import { Controller } from "@hotwired/stimulus";
import { openModal, closeModal } from "../../core/modals"

export default class extends Controller {
  static values = {
    src: String,
    attempts: { type: Number, default: 0 },
    totalAttempts: { type: Number, default: 10 },
    pollingWait: { type: Number, default: 1000}
  }

  connect() {
    if (!this.srcValue) return

    if (this.attemptsRemaining) {
      this.running = true
      openModal(this.element)
      this.retry()
    }
  }

  retry() {
    if (this.running && this.attemptsRemaining) {
      setTimeout(() => this.poll(), this.pollingWaitValue)
    } else {
      closeModal(this.element)
    }
  }

  terminate() {
    this.running = false
  }

  get attemptsRemaining() {
    return this.attemptsValue < this.totalAttemptsValue
  }

  async poll() {
    this.attemptsValue = this.attemptsValue + 1

    const response = await fetch(this.srcValue, { headers: { Accept: "text/vnd.turbo-stream.html" }, credentials: 'same-origin' })
    if (response.ok) {
      const html = await response.text()
      Turbo.renderStreamMessage(html)
      closeModal(this.element)
    } else {
      this.retry()
    }
  }
}
